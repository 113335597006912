<!-- 批量编辑 复制编辑 -->
<template>
  <div>
    <el-dialog
      title="广告样式"
      :visible="adTypeMixShow"
      width="800px"
      @close="cancle"
      append-to-body
      @open="openInit"
    >
      <!-- adInfo{{adInfo}} -->
      <el-form
        :model="adInfo"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
        v-if="adTypeMixShow"
      >
        <el-form-item
          label="广告样式："
          prop="page"
          class="pageSelect"
          require
        >
          <el-tabs
            type="border-card"
            v-model="adInfo.media_type"
            @tab-click="handleMedia"
          >
            <el-tab-pane
              label="单图"
              name="image"
              :class="leftToRight"
            >
              <div
                class="adCon"
                v-if="adInfo.media_type == 'image'"
              >
                <div
                  class="imgCon"
                  v-if="!adInfo.image"
                >
                  <el-button
                    size="small"
                    @click="selectImg('img')"
                    >从创意云选择</el-button
                  >
                  <!-- <el-upload
                    class="upload-demo"
                    action="#"
                    :http-request="uploadImg"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      @click="uploadType('img')"
                      >点击上传</el-button
                    >
                  </el-upload> -->
                  <gm-upload-material
                    ref="uploadMaterial"
                    v-model:fileList="fileList"
                    :saveAdFileParams="saveAdFileParam"
                    :on-success="onSuccess"
                    :before-upload="beforeUpload"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      >点击上传</el-button
                    >
                  </gm-upload-material>
                  <upload-tips></upload-tips>
                </div>
                <div
                  class="imgCon"
                  v-else
                >
                  <div class="tools">
                    <!-- <i class="el-icon-info"></i> -->
                    <i class="el-icon-zoom-in"></i>
                    <i
                      class="el-icon-delete"
                      @click="deleImg('img')"
                    ></i>
                    <!-- <el-upload
                      class="upload-demo"
                      action="#"
                      :http-request="uploadImg"
                      :show-file-list="false"
                      style="display: inline-block"
                      :before-upload="beforeUpload"
                    >
                      <i
                        class="el-icon-upload2"
                        @click="uploadType('img')"
                      ></i>
                    </el-upload> -->
                    <gm-upload-material
                      class="inline-block"
                      ref="uploadMaterial"
                      v-model:fileList="fileList"
                      :saveAdFileParams="saveAdFileParam"
                      :on-success="onSuccess"
                      :before-upload="beforeUpload"
                    >
                      <i
                        class="el-icon-upload2"
                        @click="uploadType('img')"
                      ></i>
                    </gm-upload-material>
                  </div>
                  <div class="imgTips">
                    <p>推荐长宽比：1:1</p>
                    <p>推荐尺寸：1080 * 1080像素</p>
                    <p>最小尺寸：600 * 600像素</p>
                    <p>图中文本内容不超过20%效果更佳</p>
                  </div>
                  <el-image
                    :src="adInfo.image"
                    :preview-src-list="[adInfo.image]"
                    fit="contain"
                    @error.once="setImg"
                  ></el-image>
                </div>
              </div>
            </el-tab-pane>
            <!-- {{ adInfo.mutiImgs }} -->
            <el-tab-pane
              label="轮播图"
              name="muti"
              :class="leftToRight"
              v-if="objective == 'OUTCOME_SALES'"
            >
              <div
                class="swiper-con"
                v-if="adInfo.media_type == 'muti'"
              >
                <draggable
                  class="list-group"
                  tag="ul"
                  v-model="adInfo.mutiImgs"
                  v-bind="dragOptions"
                  :move="onMove"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <transition-group
                    type="transition"
                    :name="'flip-list'"
                    class="dp-flex"
                  >
                    <li
                      class="list-group-item"
                      v-for="(element, i) in adInfo.mutiImgs"
                      :key="i + ''"
                    >
                      <div
                        class="adCon scrollItem"
                        v-if="i != adInfo.mutiImgs.length - 1"
                      >
                        <div
                          class="imgCon"
                          v-if="!element.image"
                        >
                          <el-button
                            size="small"
                            @click="selectImg('banner', i)"
                            >从创意云选择</el-button
                          >
                          <!-- <el-upload
                            class="upload-demo"
                            action="#"
                            :http-request="uploadImg"
                            :show-file-list="false"
                            :before-upload="beforeUpload"
                          >
                            <el-button
                              size="small"
                              type="primary"
                              @click="uploadType('banner', i)"
                              >本地上传</el-button
                            >
                          </el-upload> -->
                          <gm-upload-material
                            ref="uploadMaterial"
                            v-model:fileList="fileList"
                            :saveAdFileParams="saveAdFileParam"
                            :on-success="onSuccess"
                            :before-upload="beforeUpload"
                          >
                            <el-button
                              size="small"
                              type="primary"
                              @click="uploadType('banner', i)"
                              >本地上传</el-button
                            >
                          </gm-upload-material>
                          <upload-tips></upload-tips>
                        </div>
                        <div
                          class="imgCon"
                          v-else
                        >
                          <div class="tools">
                            <!-- <i class="el-icon-info"></i> -->
                            <i class="el-icon-zoom-in"></i>
                            <i
                              class="el-icon-delete"
                              @click="deleImg('banner', i)"
                            ></i>
                            <!-- <el-upload
                              class="upload-demo"
                              action="#"
                              :http-request="uploadImg"
                              :show-file-list="false"
                              style="display: inline-block"
                              :before-upload="beforeUpload"
                            >
                              <i
                                class="el-icon-upload2"
                                @click="uploadType('banner', i)"
                              ></i>
                            </el-upload> -->
                            <gm-upload-material
                              class="inline-block"
                              ref="uploadMaterial"
                              v-model:fileList="fileList"
                              :saveAdFileParams="saveAdFileParam"
                              :on-success="onSuccess"
                              :before-upload="beforeUpload"
                            >
                              <i
                                class="el-icon-upload2"
                                @click="uploadType('banner', i)"
                              ></i>
                            </gm-upload-material>
                          </div>
                          <div class="imgTips">
                            <p>推荐长宽比：1:1</p>
                            <p>推荐尺寸：1080 * 1080像素</p>
                            <p>最小尺寸：600 * 600像素</p>
                            <p>图中文本内容不超过20%效果更佳</p>
                          </div>
                          <el-image
                            :src="element.image"
                            :preview-src-list="[element.image]"
                            fit="contain"
                          ></el-image>
                        </div>
                      </div>
                      <div
                        class="addItem"
                        @click="addImgItem"
                        v-if="i == adInfo.mutiImgs.length - 1"
                      >
                        <i class="el-icon-circle-plus-outline"></i>
                        <p>添加素材</p>
                      </div>
                    </li>
                  </transition-group>
                </draggable>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="视频"
              name="video"
              :class="leftToRight"
            >
              <div
                class="adCon"
                v-if="adInfo.media_type == 'video'"
              >
                <div
                  class="imgCon"
                  v-if="!adInfo.image"
                >
                  <el-button
                    size="small"
                    @click="selectImg('video')"
                    >从创意云选择</el-button
                  >
                  <el-button
                    size="small"
                    @click="adSlideShow = true"
                    >创建幻灯</el-button
                  >
                  <!-- <el-upload
                    class="upload-demo"
                    action="#"
                    :http-request="uploadImg"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      @click="uploadType('video')"
                      >点击上传</el-button
                    >
                  </el-upload> -->
                  <gm-upload-material
                    ref="uploadMaterial"
                    v-model:fileList="fileList"
                    :saveAdFileParams="saveAdFileParam"
                    :on-success="onSuccess"
                    :before-upload="beforeUpload"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      @click="uploadType('video')"
                      >点击上传</el-button
                    >
                  </gm-upload-material>
                  <upload-tips></upload-tips>
                </div>

                <div
                  class="imgCon"
                  v-else
                  v-loading="videoStatus == 'processing'"
                  element-loading-text="视频FB处理中..."
                >
                  <div class="tools">
                    <i class="el-icon-zoom-in"></i>
                    <i
                      class="el-icon-delete"
                      @click="deleImg('video')"
                    ></i>
                    <!-- <el-upload
                      class="upload-demo"
                      action="#"
                      :http-request="uploadImg"
                      :show-file-list="false"
                      style="display: inline-block"
                      :before-upload="beforeUpload"
                    >
                      <i
                        class="el-icon-upload2"
                        @click="uploadType('video')"
                      ></i>
                    </el-upload> -->
                    <gm-upload-material
                      class="inline-block"
                      ref="uploadMaterial"
                      v-model:fileList="fileList"
                      :saveAdFileParams="saveAdFileParam"
                      :on-success="onSuccess"
                      :before-upload="beforeUpload"
                    >
                      <i
                        class="el-icon-upload2"
                        @click="uploadType('video')"
                      ></i>
                    </gm-upload-material>
                  </div>
                  <div class="imgTips">
                    <p>推荐长宽比：1:1</p>
                    <p>推荐尺寸：1080 * 1080像素</p>
                    <p>最小尺寸：600 * 600像素</p>
                    <p>图中文本内容不超过20%效果更佳</p>
                  </div>
                  <video
                    controls
                    :src="adInfo.image"
                    :poster="adInfo.thumbUrl"
                    v-if="fileType == 'mp4'"
                  ></video>
                  <el-image
                    :src="adInfo.image"
                    :preview-src-list="[adInfo.image]"
                    v-if="fileType == 'gif'"
                  ></el-image>
                </div>
              </div>
              <div
                class="videoTools"
                v-if="uploadNums >= 4 && videoStatus == 'processing'"
              >
                <span>视频工具栏：</span>
                <el-button
                  size="mini"
                  type="primary"
                  @click="cancleVideoUpload"
                  >取消上传</el-button
                >
              </div>
              <span>视频封面图：</span>
              <el-button
                size="small"
                @click="selectImg('img', 'videoImage')"
                :disabled="!adInfo.source_id && !videoStatus != 'ready'"
                >从创意云选择</el-button
              >
              <!-- <el-upload
                class="upload-demo"
                action="#"
                :http-request="uploadVideoImg"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :disabled="!adInfo.source_id"
                style="display: inline-block; margin-left: 20px"
              >
                <el-button
                  size="small"
                  type="primary"
                  @click="uploadType('img')"
                  :disabled="!adInfo.source_id && !videoStatus != 'ready'"
                  >点击上传</el-button
                >
              </el-upload> -->
              <gm-upload-material
                class="inline-block ml-5"
                ref="uploadMaterial"
                v-model:fileList="fileList"
                :saveAdFileParams="saveAdFileParam"
                :on-success="uploadVideoImg"
                :before-upload="beforeUpload"
                :disabled="!adInfo.source_id"
              >
                <el-button
                  size="small"
                  type="primary"
                  @click="uploadType('img')"
                  :disabled="!adInfo.source_id && !videoStatus != 'ready'"
                  >点击上传</el-button
                >
              </gm-upload-material>
              <div
                class="adCon"
                v-if="adInfo.media_type == 'video'"
                style="margin-top: 10px"
              >
                <div
                  class="thumbList"
                  v-if="thumbList"
                >
                  <div
                    class="thumbWrap"
                    v-for="(img, thumbIndex) in thumbList"
                    :key="thumbIndex"
                    @click="setVideoThumb(img.uri, thumbIndex)"
                  >
                    <el-image
                      :src="img.uri"
                      fit="contain"
                    ></el-image>
                    <i
                      class="el-icon-circle-check"
                      v-if="img.isPreferred"
                    ></i>
                  </div>
                </div>
                <div
                  class="empty"
                  v-else
                  style="width: 100%"
                >
                  <span>
                    <i class="iconfont icon-wushuju"></i>
                    <p>暂无数据</p>
                  </span>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="现有帖子"
              name="post"
              v-if="objective == 'OUTCOME_SALES' || objective == 'POST_ENGAGEMENT'"
            >
              <el-form-item
                lable="帖子id"
                style="margin-bottom: 10px"
              >
                <el-input
                  placeholder="输入帖子id或者选择帖子"
                  v-model="adInfo.post_id"
                  @change="setPostId"
                ></el-input>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane
              label="动态广告"
              name="asset_feed"
              v-if="objective == 'OUTCOME_SALES'"
            >
              <more-source-ad
                v-model="adInfo"
                :edit="true"
              ></more-source-ad>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
      </el-form>
      <img-list
        :saveAdFileParam="saveAdFileParam"
        :imgListShow="imgListShow"
        @close="close"
        @urlList="urlList"
        :uploadTypes="uploadTypes"
        :limit="1"
      ></img-list>
      <ad-slide
        :adSlideShow="adSlideShow"
        @close="close"
        @getVideoFile="getVideoFile"
      ></ad-slide>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancle">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import uploadTips from './uploadTips.vue';
import imgList from '../../../createAd/components/imgList';
import draggable from 'vuedraggable';
import adSlide from '../../../createAd/components/creatSlide';
import tools from '../../../createAd/components/tools';
import moreSourceAd from '../../../createAd/components/assetFeed/moreSourceAd';
import GmUploadMaterialMix from '@/views/adManagement/createAd/GmUploadMaterial.js';
import { saveVideoThumb, mediaFileUploadToFb, mediaFileUploadFbCleanDate, querySlideshowVideo } from '@/api/creatAd.js';
import { ossClient } from '@/utils/alioss.js';
import BMF from 'browser-md5-file';
export default {
  props: ['adTypeMsg', 'adTypeMixShow', 'objective', 'targetUserId', 'account', 'currentSelect', 'videoMap'],
  mixins: [tools, GmUploadMaterialMix],
  data() {
    return {
      adInfo: JSON.parse(JSON.stringify(this.adTypeMsg)),
      leftToRight: 'leftToRight',
      imgListShow: false,
      adSlideShow: false,
      uploadTypes: 'img',
      thumbList: [],
      videoStatus: 'ready',
      fileType: 'mp4',
      editable: true,
      accountId: this.$store.getters.accountId,
      uploadNums: 1,
      cancleVideoUploadFlag: false,
      uploadVideoFbId: '',
      uploadVideoFbClearNum: 0,
      uploadVideoFbFlag: false,
      uploadVideoFbResult: null,
    };
  },
  components: {
    uploadTips,
    imgList,
    draggable,
    adSlide,
    moreSourceAd,
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost',
      };
    },
  },
  methods: {
    close(v) {
      this[v] = false;
    },
    cancle() {
      this.adInfo = {};
      this.$emit('update:adTypeMixShow', false);
      console.log(this.thumbList, 'this.thumbList');

      this.thumbList = [];
    },
    emitMsg() {
      let obj = {
        media_type: this.adInfo.media_type,
        mediaSourceType: this.adInfo.sourceType,
      };

      if (this.adInfo.media_type == 'video') {
        this.cancleVideoUploadFlag = true;
        obj.videoStatus = this.videoStatus;
        obj.image = this.adInfo.image;
        obj.thumbUrl = this.adInfo.thumbUrl;
        obj.source_id = this.adInfo.source_id;
        obj.videoId = this.adInfo.videoId;
      } else if (this.adInfo.media_type == 'image') {
        obj.image = this.adInfo.image;
        obj.source_id = this.adInfo.source_id;
      } else if (this.adInfo.media_type == 'post') {
        obj.post_id = this.adInfo.post_id;
      } else if (this.adInfo.media_type == 'muti') {
        obj.mutiImgs = [...this.adInfo.mutiImgs];
      } else if (this.adInfo.media_type == 'asset_feed') {
        let { optimizationType, actionTypes, linkUrls, ...fbAssetFeedSpec } = this.adInfo.fbAssetFeedSpec;
        console.log(fbAssetFeedSpec);
        obj.fbAssetFeedSpec = fbAssetFeedSpec;
      }
      this.$emit('getAdTypeMsg', obj);
    },
    sure() {
      console.log(this.adInfo);
      // 视频
      if (this.uploadVideoFbFlag) {
        this.$message({
          type: 'warning',
          message: '请等视频上传fb',
        });
        return false;
      }
      this.emitMsg();
      this.cancle();
    },
    openInit() {
      this.$nextTick(() => {
        this.adInfo = { ...this.adTypeMsg };
        this.adInfo.sourceType = this.adTypeMsg.mediaSourceType;
        this.videoStatus = this.adInfo.videoStatus;
        this.cancleVideoUploadFlag = false;
        console.log(this.adInfo);
        console.log(this.$parent.videoMap, 'this.$parent.videoMap');
        this.thumbList = this.$parent.videoMap[this.adInfo.videoId] ? this.$parent.videoMap[this.adInfo.videoId] : [];
        if (this.adInfo.media_type == 'video' && this.adInfo.videoStatus == 'processing') {
          if (this.adInfo.videoId) {
            this.getVideoUrl(this.adInfo.videoId, res.data.sourceType);
          }
        }
        if (this.adInfo.media_type == 'muti') {
          this.adInfo.mutiImgs.push({ name: '', fixed: false });
        }
      });
      console.log(this.adInfo, this.adTypeMsg, 'this.adInfo');
      // if(this.adInfo.media_type == 'video'){
      //     this.videoStatus = 'ready'
      // }
      //
      // console.log(this.adInfo);
      // return JSON.parse(JSON.stringify(this.adTypeMsg))
    },
    // 监听拖拽
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
    },
    // 点击切换广告样式
    async handleMedia(v) {
      let text = v.name == 'image' ? '单图' : v.name == 'video' ? '视频' : v.name == 'post' ? '帖子' : '轮播';
      if (v.name == 'muti' && (!this.adInfo.mutiImgs || !this.adInfo.mutiImgs.length)) {
        this.adInfo.mutiImgs = [
          { name: '', fixed: false },
          { name: '', fixed: false },
          { name: '', fixed: false },
        ];
        this.$set(this.adInfo, 'mutiImgs', [
          { name: '', fixed: false },
          { name: '', fixed: false },
          { name: '', fixed: false },
        ]);
      }
      if (v.name == 'asset_feed') {
        let fbAssetFeedSpec = {
          titles: [''],
          mDescriptions: [''],
          mBodies: [''],
          imgList: null,
          videos: [],
          mAdFormats: ['SINGLE_IMAGE'],
          actionTypes: [''],
          linkUrls: [''],
        };
        this.$set(this.adInfo, 'fbAssetFeedSpec', fbAssetFeedSpec);
      }
      this.$set(this.adInfo, 'image', '');
      this.$set(this, 'videoStatus', false);
      this.videoStatus = false;
      this.adInfo.image = '';
    },
    // 从创意云选择
    selectImg(type, index) {
      this.uploadTypes = type;
      this.uploadIndex = index ? index : 0;
      // 上传视频封面
      if (index == 'videoImage') {
        this.imgType = 'videoImage';
        this.uploadIndex = 0;
      } else {
        this.imgType = '';
      }
      this.imgListShow = true;
    },
    uploadType(type, index) {
      this.uploadTypes = type;
      this.uploadIndex = index ? index : 0;
    },
    // 上传文件
    // uploadImg(file) {
    //   let formData = new FormData();
    //   let key = '';
    //   if (this.adInfo.media_type == 'video') {
    //     key = 'VIDEO';
    //   } else if (this.adInfo.media_type == 'image' || this.adInfo.media_type == 'muti') {
    //     key = 'IMAGE';
    //   }
    //   let fileType = file.file.type;
    //   let isJPG =
    //     fileType == 'image/jpeg' || fileType == 'image/png' || fileType == 'image/jpg' || fileType == 'image/webp';
    //   let isMP4 = fileType === 'video/mp4' || fileType === 'image/gif';
    //   if (!((key == 'VIDEO' && isMP4) || ((key = 'IMAGE') && isJPG))) {
    //     this.$message({
    //       type: 'warning',
    //       message: '素材类型不一致，请重新选择素材上传',
    //     });
    //     return false;
    //   }
    //   console.log(this.uploadTypes);
    //   formData.append('file', file.file);
    //   formData.append('type', key);
    //   this.$showLoading();
    //   this.newUpload(formData, { type: key })
    //     .then((res) => {
    //       if (res.code == 0) {
    //         // this.$message({
    //         //   type: 'success',
    //         //   message: '上传成功',
    //         // });
    //         if (this.uploadTypes == 'img' || this.uploadTypes == 'video') {
    //           // console.log(res.data.url);
    //           this.adInfo.image = res.data.url;
    //           this.adInfo.source_id = res.data.id;
    //           if (this.uploadTypes == 'video') {
    //             this.uploadVideoFb(res.data.id);
    //           }
    //           // console.log( this.adInfo.image);
    //         } else {
    //           this.$set(this.adInfo.mutiImgs[this.uploadIndex], 'image', res.data.url);
    //           this.$set(this.adInfo.mutiImgs[this.uploadIndex], 'source_id', res.data.id);
    //         }
    //         this.$forceUpdate();
    //       }
    //     })
    //     .finally(() => {
    //       this.$hideLoading();
    //     });
    // },
    onSuccess(response, file) {
      if (this.uploadTypes == 'img' || this.uploadTypes == 'video') {
        // console.log(res.data.url);
        this.adInfo.image = response.url;
        this.adInfo.source_id = response.id;
        this.adInfo.sourceType = response.sourceType;
        if (this.uploadTypes == 'video') {
          this.uploadVideoFb(response.id, response.sourceType);
        }
        // console.log( this.adInfo, 'this.adInfo');
      } else {
        this.$set(this.adInfo.mutiImgs[this.uploadIndex], 'image', response.url);
        this.$set(this.adInfo.mutiImgs[this.uploadIndex], 'source_id', response.id);
        this.$set(this.adInfo.mutiImgs[this.uploadIndex], 'mediaSourceType', response.sourceType);
      }
      this.$forceUpdate();
    },
    // 添加轮播图
    addImgItem() {
      let obj = { name: '', fixed: false };
      this.adInfo.mutiImgs.push(obj);
      this.$set(this.adInfo, 'mutiImgs', this.adInfo.mutiImgs);
      console.log(this.adInfo);
      this.$forceUpdate();
    },
    // 删除图片
    deleImg(type, i) {
      // console.log(type);
      if (type == 'img' || type == 'video') {
        this.adInfo.image = '';
        this.videoStatus = false;
        this.adInfo.source_id = '';
        this.cancleVideoUploadFlag = false;
        // 删除视频封面
        this.adInfo.thumbUrl = '';
        this.thumbList = [];
      } else if (type == 'thumbUrl') {
        this.adInfo.thumbUrl = '';
      } else {
        this.$set(this.adInfo.mutiImgs[i], 'image', '');
        this.$set(this.adInfo.mutiImgs[i], 'source_id', '');
      }
      this.$forceUpdate();
    },
    // 接收幻灯片地址
    setVideoUrl(v, thumbList, isupload) {
      console.log(v, thumbList, isupload, 'setVideoUrl');
      this.adSlideShow = false;
      this.adInfo.image = v.url;
      this.adInfo.source_id = v.id;
      this.videoStatus = 'ready';
      this.thumbList = [...thumbList];
      const thumbIndex = thumbList.findIndex((v) => v.isPreferred);
      this.setVideoThumb(thumbList[thumbIndex].uri, thumbIndex, isupload);
    },
    // 设置封面
    setVideoThumb(url, index, isupload) {
      // console.log(index);
      if (index || index === 0) {
        this.thumbList.map((v, ind) => {
          if (index == ind) {
            this.$set(this.thumbList[ind], 'isPreferred', true);
          } else {
            this.$set(this.thumbList[ind], 'isPreferred', false);
          }
        });
      }
      if (!isupload) {
        const params = {
          sourceType: this.adInfo.sourceType,
          videoFileId: this.adInfo.source_id,
          videoThumbUrl: url,
        };
        this.uploadThumb(params);
      }
    },
    // 从素材库获取文件
    urlList(v) {
      this.chooseList = v;
      // console.log(v);
      let img = v[v.length - 1];
      console.log(img, this.uploadTypes, 'this.uploadTypes');
      if (this.uploadTypes == 'img' || this.uploadTypes == 'video') {
        console.log(this.imgType, 'this.imgType');

        if (this.imgType === 'videoImage') {
          let obj = {
            isPreferred: true,
            uri: img.url,
          };
          console.log(this.thumbList, 'this.thumbList');
          this.thumbList.unshift(obj);
          this.setVideoThumb(img.url, 0);
        } else {
          if (this.uploadTypes == 'video') {
            this.uploadVideoFb(img.id, img.sourceType);
          }
          this.adInfo.image = img.url;
          this.adInfo.source_id = img.id;
          this.adInfo.sourceType = img.sourceType;
          this.adInfo.mediaSourceType = img.sourceType;
          this.adInfo.thumbUrl = img.thumbUrl;
        }
      } else {
        this.$set(this.adInfo.mutiImgs[this.uploadIndex], 'image', img.url);
        this.$set(this.adInfo.mutiImgs[this.uploadIndex], 'source_id', img.id);
        this.$set(this.adInfo.mutiImgs[this.uploadIndex], 'mediaSourceType', img.sourceType);
      }
      this.$forceUpdate();
    },
    // 接收幻灯片视频文件
    getVideoFile(file) {
      this.adInfo.image = file.url;
      this.adInfo.source_id = file.id;
      this.adSlideShow = false;
      this.uploadVideoFb(file.id, file.sourceType);
    },
    uploadVideoImg(response) {
      if (!this.adInfo.source_id) {
        this.$message({
          type: 'warning',
          message: '请先上传视频',
        });
        return false;
      }
      const params = {
        sourceType: this.adInfo.sourceType,
        videoFileId: this.adInfo.source_id,
        videoThumbUrl: response.url,
      };
      this.uploadThumb(params, 'bendi');
    },
    // 上传封面
    uploadThumb(formData, type) {
      console.log(formData, 'formData');

      const params = {
        ...formData,
        uid: this.userInfo.id,
        orgCode: this.userInfo.orgCode,
        accountId: this.$store.getters.accountId,
      };
      this.$showLoading();
      saveVideoThumb(params)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: '上传成功',
            });
            this.adInfo.thumbUrl = res.data.thumbUrl;
            if (type && type == 'bendi') {
              let obj = {
                isPreferred: true,
                uri: res.data.thumbUrl,
              };
              this.thumbList.map((v) => {
                v.isPreferred = false;
              });
              this.thumbList.unshift(obj);
            }
            this.$forceUpdate();
          }
        })
        .finally(() => {
          this.$hideLoading();
        });
    },
    // 清空数据库视频数据，重新上传
    cleanDateMediaFileUploadFb() {
      this.uploadVideoFbClearNum += 1;
      // let formData = new FormData();
      // formData.append('mediaFileId', this.uploadVideoFbId);
      // formData.append('accountId', this.accountId);
      let params = {
        accountId: this.$store.getters.accountId,
        sourceType: this.adInfo.sourceType || 'SELF_MATERIAL',
        mediaId: this.adInfo.source_id,
      };
      if (this.uploadVideoFbResult?.videoId) {
        params.videoId = this.uploadVideoFbResult.videoId;
      }
      this.$showLoading();
      mediaFileUploadFbCleanDate(params)
        .then((res) => {
          if (res.code == 0) {
            this.adInfo.videoId = res.data.videoId;
            this.getVideoUrl(res.data.videoId, res.data.sourceType);
          }
        })
        .finally(() => {
          this.$hideLoading();
        });
    },
    // 上传视频到FB
    uploadVideoFb(mediaId, sourceType) {
      this.uploadVideoFbId = mediaId;
      this.uploadVideoFbClearNum = 0;
      let params = { mediaId, sourceType, accountId: this.$store.getters.accountId };
      this.videoStatus = null;
      this.uploadVideoFbFlag = true;
      // this.$showLoading();
      mediaFileUploadToFb(params)
        .then((res) => {
          this.uploadVideoFbFlag = false;
          // this.$hideLoading();
          if (res.code == 0) {
            this.uploadVideoFbResult = res.data;
            if (res.data.videoStatus === 'error') {
              this.cleanDateMediaFileUploadFb();
              return;
            }
            this.videoStatus = 'processing';
            this.adInfo.videoId = res.data.videoId;
            this.getVideoUrl(res.data.videoId, res.data.sourceType);
          } else {
            this.videoStatus = null;
          }
        })
        .finally(() => {
          this.$hideLoading();
        });
    },
    // 轮训上传进度
    getVideoUrl(id, sourceType) {
      querySlideshowVideo({ sourceType, videoId: id, accountId: this.account })
        .then((res) => {
          // return;
          if (res.code == 0) {
            this.videoStatus = res.data.videoStatus;
            // this.$emit('videoStatus',res.data.videoStatus)
            if (res.data.videoStatus == 'processing') {
              console.log(this.cancleVideoUploadFlag);
              if (this.cancleVideoUploadFlag) return;
              this.timer = setTimeout(() => {
                this.getVideoUrl(id, sourceType), (this.uploadNums += 1);
              }, 5000);
            } else if (res.data.videoStatus == 'ready') {
              console.log(res.data.mediaBaseInfo.id, this.adInfo.source_id, 'querySlideshowVideo');
              if (res.data.videoId == this.adInfo.videoId) {
                this.videoStatus = 'ready';
                this.adInfo.videoStatus = 'ready';
                this.adInfo.videoId = res.data.videoId;
                this.adInfo.thumbUrl = res.data.mediaBaseInfo.thumbUrl;
                // this.thumbList = res.data.thumbnails;
                // this.$emit('setThumbList', res.data.videoId, res.data.thumbnails);
                console.log(res.data.mediaBaseInfo.thumbUrl, this.adInfo, 'this.adInfo');

                this.setVideoUrl(res.data.mediaBaseInfo, res.data.thumbnails, false);
              }
              console.log(this.adInfo);
              this.$hideLoading();
              // this.$emit('setVideoUrl',res.data.mediaFile);
            } else if (res.data.videoStatus == 'error' && this.uploadVideoFbClearNum < 3) {
              this.cleanDateMediaFileUploadFb();
            } else {
              this.$hideLoading();
              this.videoStatus = 'error';
              this.adInfo.image = res.data.url;
              this.adInfo.source_id = res.data.id;
              this.uploadNums = 1;
              this.cancleVideoUploadFlag = false;
              this.$message({
                type: 'warning',
                message: '失败,请重新上传视频',
              });
            }
            this.$forceUpdate();
          } else {
            this.$hideLoading();
            this.videoStatus = 'error';
            this.adInfo.image = res.data.url;
            this.adInfo.source_id = res.data.id;
            this.uploadNums = 1;
            this.cancleVideoUploadFlag = false;
            this.$message({
              type: 'warning',
              message: '失败,请重新上传视频',
            });
          }
        })
        .catch((res) => {
          this.$hideLoading();
          this.videoStatus = 'error';
          // this.adInfo.image = res.data.url;
          // this.adInfo.source_id = res.data.id;
          this.uploadNums = 1;
          this.cancleVideoUploadFlag = false;
          this.$message({
            type: 'warning',
            message: '失败,请重新上传视频',
          });
        });
    },
    cancleVideoUpload() {
      this.adInfo.videoStatus = 'error';
      this.adInfo.image = null;
      this.adInfo.source_id = null;
      this.uploadNums = 1;
      this.cancleVideoUploadFlag = true;
    },
    beforeUpload(file) {
      let isJPG = true;
      let isMP4 = true;
      // console.log(file);

      if (this.uploadTypes == 'video') {
        isMP4 = file.type === 'video/mp4';
        // if (file.type === 'image/gif') {
        //   this.fileType = 'gif';
        // }
        if (!isMP4) {
          this.$message.warning('请上传正确视频格式');
          return false;
        }
      }
      if (this.uploadTypes == 'img' || this.uploadTypes == 'banner') {
        // console.log(file.type);
        isJPG =
          file.type == 'image/jpeg' ||
          file.type == 'image/png' ||
          file.type == 'image/jpg' ||
          file.type == 'webp' ||
          (this.uploadTypes == 'img' && file.type === 'image/gif');
        if (!isJPG) {
          this.$message.warning('请上传正确图片格式');
          return false;
        }
      }
      if (file.size / 1024 / 1024 > 100) {
        this.$message.warning('上传文件不能大于100M');
        return false;
      }
      // if (this.uploadTypes == 'banner') {
      //   if (!isJPG && !isMP4) {
      //     this.$message.warning('请上传正确图片或者视频格式');
      //     return false;
      //   }
      // }
    },
    // 输入网址提取帖子id
    setPostId(v) {
      let reg = /\d+\/posts\/\d+/;
      if (v.match(reg)) {
        let postMsg = v.match(reg)[0];
        let postId = postMsg.split('/posts/')[0] + '_' + postMsg.split('/posts/')[1];
        this.$set(this.adInfo, 'post_id', postId);
      } else {
        this.$set(this.adInfo, 'post_id', v);
      }
    },
  },
};
</script>
<style>
.el-dialog {
  max-height: 90vh;
}
</style>
<style lang="scss" scoped>
.demo-ruleForm {
  max-height: 600px;
  overflow: auto;
}
.flip-list-move {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.no-move {
  -webkit-transition: -webkit-transform 0s;
  transition: -webkit-transform 0s;
  transition: transform 0s;
  transition: transform 0s, -webkit-transform 0s;
}
.list-group {
  min-height: 20px;
  width: 100%;
  overflow-x: scroll;
  /*滚动条样式*/
  &::-webkit-scrollbar {
    // width: 4px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
  span {
    white-space: nowrap;
  }
}
.list-group-item {
  cursor: move;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  height: 260px;
  box-sizing: border-box;
  .scrollItem {
    width: 246px;
  }
  .addItem {
    text-align: center;
    padding: 83px 10px;
    border: 1px dashed #ddd;
    cursor: pointer;
  }
}
.list-group-item i {
  cursor: pointer;
}
.thumbList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-height: 320px;
  overflow-y: auto;
  .thumbWrap {
    width: 120px;
    height: 120px;
    margin: 8px;
    cursor: pointer;
    position: relative;
    i {
      position: absolute;
      top: -5px;
      right: -5px;
      background: #333;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      text-align: center;
      line-height: 16px;
      color: #fff;
      cursor: pointer;
    }
  }
  .el-image {
    width: 100%;
    height: 100%;
  }
}
.imgCon {
  width: 248px;
  height: 248px;
  border: 1px dashed #ddd;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  margin: 0 auto;
  position: relative;
  .imgTips {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 222;
    padding: 10px;
    width: 100%;
    display: none;
    p {
      font-size: 12px;
      color: #fff;
      line-height: 1.5;
    }
  }
  &:hover {
    .tools {
      display: block;
      .el-icon-info {
        &:hover {
        }
      }
    }
  }

  .tools {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 22;
    display: none;
    i {
      font-size: 16px;
      margin: 5px;
      cursor: pointer;
    }
  }
  .el-image,
  video {
    width: 100%;
    height: 100%;
  }
  .el-upload__tip {
    text-align: left;
  }
}
</style>
